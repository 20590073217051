import React from "react";
import BackgroundImage from '../assests/career-growth.jpg';

var sectionStyle = {
        height: "100%",
    backgroundPosisition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'url('+ BackgroundImage + ')'
  };

const Career = () => {

    return (
      <section
        id="career"
        className="section-services section-padding section-meta onepage-section" style={sectionStyle}
      >
        <div className="container">
          <div className="section-title-area">
            <h2 className="section-title">CURRENT OPENINGS</h2>
            <p style={{color: 'white', fontSize: '20px', borderBottom: '2px dotted'}}>careers@rockettech-solutions.com</p>
          </div>
          <div className="row">
            <div className="col-sm-4 col-lg-4 career-box wow slideInUp">
              <div className="service-item career-item">
                <div className="service-content opening">
                  <div className="row">
                    <div className="col-lg-2 opening-number">2</div>
                    <div className="col-lg-10">
                      <h4 className="service-title">Front-End Developer :</h4>
                      <div className="txt-color">
                        ReactJS
                        <br />
                        VueJs <br />
                        Angular
                        <br />
                        (1 to 3 years experience)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-4 career-box wow slideInUp">
              <div className="service-item career-item">
                <div className="service-content opening">
                  <div className="row">
                    <div className="col-lg-2 opening-number">1</div>
                    <div className="col-lg-10">
                      <h4 className="service-title">Web Designer :</h4>
                      <div className="txt-color">
                        Photoshop <br />
                        HTML5
                        <br />
                        css3
                        <br />
                        (1 to 3 years experience)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-4 career-box wow slideInUp">
              <div className="service-item career-item">
                <div className="service-content opening">
                  <div className="row">
                    <div className="col-lg-2 opening-number">2</div>
                    <div className="col-lg-10">
                      <h4 className="service-title">Back-End Developers :</h4>
                      <div className="txt-color">
                        Dot Net <br />
                        Dot Net Core
                        <br />
                        (1 to 3 Years experience)
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{marginTop: '50px'}}>
            <div className="col-lg-12 career-form">
              <div
                role="form"
                className="wpcf7"
                id="wpcf7-f1605-o1"
                lang="en-US"
                dir="ltr"
              >
                
                <form
                  action="/#"
                  method="post"
                  className="wpcf7-form init"
                >
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <label>
                        Your name<span className="text-danger" >*</span>
                        <br />
                        <span className="wpcf7-form-control-wrap your-name">
                          <input
                            type="text"
                            name="your-name"
                            value=""
                            size={40}
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <label>
                        Your email<span className="text-danger">*</span>
                        <br />
                        <span className="wpcf7-form-control-wrap your-email">
                          <input
                            type="email"
                            name="your-email"
                            value=""
                            size={40}
                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <label>
                        Phone<span className="text-danger">*</span>
                        <br />
                        <span className="wpcf7-form-control-wrap phone">
                          <input
                            type="tel"
                            name="phone"
                            value=""
                            size={40}
                            className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <label>
                        Position Applied For<span className="text-danger">*</span>
                        <br />
                        <span className="wpcf7-form-control-wrap position">
                          <input
                            type="text"
                            name="position"
                            value=""
                            size={40}
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <label>
                        Experience<span className="text-danger">*</span>
                        <br />
                        <span className="wpcf7-form-control-wrap experience">
                          <input
                            type="text"
                            name="experience"
                            value=""
                            size={40}
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <label>
                        CV/Resume<span className="text-danger">*</span>
                        <br />
                        <span className="wpcf7-form-control-wrap resume">
                          <input
                            type="file"
                            name="resume"
                            className="wpcf7-form-control wpcf7-file wpcf7-validates-as-required"
                            accept=".pdf,.txt,.jpg,.jpeg,.png,.gif,.doc,.docx,.ppt"
                            aria-required="true"
                            aria-invalid="false"
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <input
                        type="button"
                        value="Submit"
                        className="wpcf7-form-control wpcf7-submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Career;