import React from "react";
import HomeImageFirst from "../assests/about3.jpg";
import HomeImageSecond from "../assests/homeImageOne.png";
import {Link} from 'react-scroll';

const Home = () => {
    return (
     
        <section
          id="hero"
          className="carousel  slide carousel-fade  fade-carousel "
          data-bs-ride="carousel"
        >
          <div className=""></div>

          <div className="carousel-inner">
            <div className="carousel-item slides active item ">
              <div
                className="slide-1"
                style={{ backgroundImage: "url(" + HomeImageFirst + ")" }}
              ></div>
            </div>
            <div className="carousel-item  slides item ">
              <div
                className="slide-2"
                style={{ backgroundImage: "url(" + HomeImageSecond + ")" }}
              ></div>
            </div>
            <div className="container">
              <div className="hero hero__content hero-content-style1">
                <h2 className="hero-large-text">
                  We
                  <span className="js-rotating morphext">
                    <span className="animated flipInX">Create</span>
                  </span>
                </h2>
                <Link  activeClass="active" to="services" spy={true} offset={-76}
                  
                  className="btn btn-theme-primary btn-lg"
                >
                  Our Services
                </Link>
                <Link  activeClass="active" to="contact" spy={true} offset={-76}
                 
                  className="btn btn-secondary-outline btn-lg"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        
      </section>
    );

}

export default Home;