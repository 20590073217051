import React from "react";
 
const Services = () => {
    
        return (
            <section
            id="services"
            className="section-features section-padding section-meta onepage-section"
          >
            <div className="container">
              <div className="section-title-area">
                <h2 className="section-title">Services</h2>
                <div className="section-desc">
                  <p>
                    <strong>Rocket Tech Solutions</strong> offers multiple
                    digital solutions under one <em>ROOF</em>.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="row">
                  <div
                    className="feature-item col-lg-3 col-sm-6 wow slideInUp"
                    
                  >
                    <div className="feature-media">
                      <span className="fa-stack fa-5x srv-icon1"
                        ><i className="fa fa-stack-2x icon-background-default"></i>
                        <i
                          className="feature-icon fa fa fa-file-code-o fa-stack-1x"
                        ></i></span>
                    </div>
                    <h4>

                    </h4>
                    <div className="feature-item-content">
                      <p>
                        UI/UX Design<br />
                        Web Based Customer Solution<br />
                        Offshore IT Services
                      </p>
                    </div>
                  </div>
                  <div
                    className="feature-item col-lg-3 col-sm-6 wow slideInUp"
                   
                  >
                    <div className="feature-media">
                      <span className="fa-stack fa-5x srv-icon2"
                        ><i className="fa fa-stack-2x icon-background-default"></i>
                        <i className="feature-icon fa fa fa-mobile fa-stack-1x"></i></span>
                    </div>
                    <h4> </h4>
                    <div className="feature-item-content">
                      <p>
                        Android<br />
                        IOS<br />
                        Native App Development<br />
                        Cross Platform App Development
                      </p>
                    </div>
                  </div>
                  <div
                    className="feature-item col-lg-3 col-sm-6 wow slideInUp"
                    
                  >
                    <div className="feature-media">
                      <span className="fa-stack fa-5x srv-icon3"
                        ><i className="fa fa-stack-2x icon-background-default"></i>
                        <i
                          className="feature-icon fa fa fa-comments-o fa-stack-1x"></i></span>
                    </div>
                    <h4>

                    </h4>
                    <div className="feature-item-content">
                      <p>
                        Hardware Integration<br />
                        IT Strategic Consultation<br />
                        Software Consulting<br />
                        IT Support And Maintenance
                      </p>
                    </div>
                  </div>
                  <div
                    className="feature-item col-lg-3 col-sm-6 wow slideInUp"
                    
                  >
                    <div className="feature-media">
                      <span className="fa-stack fa-5x srv-icon4"
                        ><i className="fa fa-stack-2x icon-background-default"></i>
                        <i
                          className="feature-icon fa fa fa-lightbulb-o fa-stack-1x"
                        ></i></span>
                    </div>
                    <h4> </h4>
                    <div className="feature-item-content">
                      <p>
                        Hosting<br />
                        Monitoring<br />
                        DevOps<br />
                        Cloud Native Development
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    
} 

export default Services;