import React from "react";
import AzureImage from "../assests/azure.png";
import GoogleCloud from "../assests/googlecloud.png";
import Aws from "../assests/aws.png";
import DigitalOcean from "../assests/digitalocean.png";
import DotNet from "../assests/dotnet.png";
import Phython from "../assests/phython.png";
import Sql from "../assests/sql.png";
import Postgre from "../assests/postgre.png";
import CosmosDb from "../assests/cosmosdb.png";
import MongoDb from "../assests/mongodb-1.png";
import NodeJs from "../assests/nodejs.png";
import Angular from "../assests/angular.png";
import ReactImg from "../assests/react.png";
import Vue from "../assests/vue.png";
import Andriod from "../assests/android.png";
import Ios from "../assests/ios.png";
import PhoneGap from "../assests/phonegap.png";
import Iconic from "../assests/ionic.png";
import ReactNative from "../assests/reactnative.png";
import Flutter from "../assests/flutter.png";

const Technologies = () =>{
    return (
      <section
        id="gallery"
        className="section-gallery section-padding section-meta onepage-section"
      >
        <div className="g-layout-default container">
          <div className="section-title-area">
            <h2 className="section-title">Technologies</h2>
          </div>
          <div className="gallery-content">
            <div className="gallery-grid g-zoom-in enable-lightbox g-col-6">
              <a
                href={AzureImage}
                className="g-item"
                title="azure"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={AzureImage}
                      alt="azure"
                    />
                  </span>
                </span>
              </a>
              <a
                href={GoogleCloud}
                className="g-item"
                title="googlecloud"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={GoogleCloud}
                      alt="googlecloud"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Aws}
                className="g-item"
                title="aws"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img src={Aws} alt="aws" />
                  </span>
                </span>
              </a>
              <a
                href={DigitalOcean}
                className="g-item"
                title="digitalocean"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={DigitalOcean}
                      alt="digitalocean"
                    />
                  </span>
                </span>
              </a>
              <a
                href={DotNet}
                className="g-item"
                title="dotnet"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={DotNet}
                      alt="dotnet"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Phython}
                className="g-item"
                title="phython"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={Phython}
                      alt="phython"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Sql}
                className="g-item"
                title="sql"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img src={Sql} alt="sql" />
                  </span>
                </span>
              </a>
              <a
                href={Postgre}
                className="g-item"
                title="postgre"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={Postgre}
                      alt="postgre"
                    />
                  </span>
                </span>
              </a>
              <a
                href={CosmosDb}
                className="g-item"
                title="cosmosdb"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={CosmosDb}
                      alt="cosmosdb"
                    />
                  </span>
                </span>
              </a>
              <a
                href={MongoDb}
                className="g-item"
                title="mongodb"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={MongoDb}
                      alt="mongodb"
                    />
                  </span>
                </span>
              </a>
              <a
                href={NodeJs}
                className="g-item"
                title="nodejs"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={NodeJs}
                      alt="nodejs"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Angular}
                className="g-item"
                title="angular"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={Angular}
                      alt="angular"
                    />
                  </span>
                </span>
              </a>
              <a
                href={ReactImg}
                className="g-item"
                title="react"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={ReactImg}
                      alt="react"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Vue}
                className="g-item"
                title="vue"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img src={Vue} alt="vue" />
                  </span>
                </span>
              </a>
              <a
                href={Andriod}
                className="g-item"
                title="android"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={Andriod}
                      alt="android"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Ios}
                className="g-item"
                title="ios"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img src={Ios} alt="ios" />
                  </span>
                </span>
              </a>
              <a
                href={PhoneGap}
                className="g-item"
                title="phonegap"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={PhoneGap}
                      alt="phonegap"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Iconic}
                className="g-item"
                title="ionic"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={Iconic}
                      alt="ionic"
                    />
                  </span>
                </span>
              </a>
              <a
                href={ReactNative}
                className="g-item"
                title="reactnative"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={ReactNative}
                      alt="reactnative"
                    />
                  </span>
                </span>
              </a>
              <a
                href={Flutter}
                className="g-item"
                title="flutter"
              >
                <span className="inner">
                  <span className="inner-content">
                    <img
                      src={Flutter}
                      alt="flutter"
                    />
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Technologies;