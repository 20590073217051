import React from "react";

const Contact = () => {
    return(
        <section
        id="contact"
        className="section-contact section-padding section-meta onepage-section"
      >
        <div className="container">
          <div className="section-title-area">
            <h2 className="section-title">Get in touch</h2>
          </div>
          <div className="row">
            <div className="contact-form col-sm-6 wow slideInUp">
                <div className="wpforms-container wpforms-container-full" id="wpforms-1443">
                  <form
                    id="wpforms-form-1443"
                    className="wpforms-validate wpforms-form"
                  >
                    
                    <div className="wpforms-field-container">
                      <div
                     
                        className="wpforms-field wpforms-field-name"
                        data-field-id="0"
                      >
                        <label
                          className="wpforms-field-label"
                          
                          >Name
                          <span className="wpforms-required-label">*</span></label
                        >
                        <div className="wpforms-field-row wpforms-field-medium">
                          <div
                           className="wpforms-field-row-block wpforms-first wpforms-one-half"
                          >
                            <input
                              type="text"
                              
                              className="wpforms-field-name-first wpforms-field-required"
                              
                            /><label
                              
                            className="wpforms-field-sublabel after"
                              >First</label
                            >
                          </div>
                          <div className="wpforms-field-row-block wpforms-one-half">
                            <input
                              type="text"
                            
                              className="wpforms-field-name-last wpforms-field-required"
                              
                            /><label
                              
                            className="wpforms-field-sublabel after"
                              >Last</label
                            >
                          </div>
                        </div>
                      </div>
                      <div
                       
                        className="wpforms-field wpforms-field-email"
                        
                      >
                        <label
                          className="wpforms-field-label"
                          
                          >Email
                          <span className="wpforms-required-label">*</span></label
                        ><input
                          type="email"
                          
                          className="wpforms-field-medium wpforms-field-required"
                          
                        />
                      </div>
                      <div
                        
                        className="wpforms-field wpforms-field-textarea"
                        
                      >
                        <label
                          className="wpforms-field-label"
                          
                          >Comment or Message
                          <span className="wpforms-required-label">*</span></label
                        >
                        <textarea className="wpforms-field-medium wpforms-field-required"></textarea>
                      </div>
                    </div>
                    <div className="wpforms-submit-container">
                      <button type="submit" className="wpforms-submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              
              </div>

            <div
              className="col-sm-6 wow slideInUp"
              
            >
              <br /><br />
              <div className="address-box">
                <h3>Registered Address:</h3>

                <div className="address-contact">
                  <span className="fa-stack"
                    ><i className="fa fa-circle fa-stack-2x"></i
                    ><i className="fa fa-map-marker fa-stack-1x fa-inverse"></i
                  ></span>

                  <div className="address-content">
                    c-1/2, 1st floor, above right choice super mart,
                    <br />Mahakal Vanijiya Kendra, RTO Road <br />
                    Ujjain, M.P. 456006
                  </div>
                </div>

                <div className="address-contact">
                  <span className="fa-stack"
                    ><i className="fa fa-circle fa-stack-2x"></i
                    ><i className="fa fa-phone fa-stack-1x fa-inverse"></i
                  ></span>
                  <div className="address-content">+91-8109033228</div>
                </div>

                <div className="address-contact">
                  <span className="fa-stack"
                    ><i className="fa fa-circle fa-stack-2x"></i
                    ><i className="fa fa-envelope-o fa-stack-1x fa-inverse"></i
                  ></span>
                  <div className="address-content">
                    <a href="mailto:inquiries@rockettech-solutions.com"
                      >inquiries@rockettech-solutions.com</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
export default Contact;