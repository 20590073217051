import React from "react";

const AboutUs = () => {
    
        return (
              <section
                id="about"
                className="section-about section-padding onepage-section" style={{height:'100vh', overflow:"visible"}}
              >
                <div className="container">
                  <div className="section-title-area">
                    <h2 className="section-title">About Us</h2>
                    <div className="section-desc">
                      <h4>
                        As <strong>rocketTECH Solutions</strong> we believe in
                        providing <em>High quality, Effective and Smart</em> solutions.
                      </h4>
                      <p style={{fontSize: "15px"}}>
                        We work closely with customers to understand the
                        business and processes and their objectives, we then
                        build solid architecture and application to achieve it.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-4 col-sm-6 wow slideInUp"
                      
                    >
                      <h3>Our Vision</h3>
                      <p>
                        Work make sense only when it creates value for the
                        world.
                      </p>
                    </div>
                    <div
                     className="col-lg-4 col-sm-6 wow slideInUp"
                      
                    >
                      <h3>What We Do</h3>

                      <p>
                        We are experts to deliver customer’s digital needs, fast
                        implementation and build flexible solution.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-sm-6 wow slideInUp"
                      
                    >
                      <h3>Our Mission</h3>
                      <p>
                        To deliver excellence solution and services for our
                        customers to achieve core competence.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            
        )       
    
}

export default AboutUs;