import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/header';
import HomeComponent from './components/home';
import AboutUs from './components/aboutUs';
import Services from './components/services';
import Technologies from './components/technologies';
import Career from './components/career';
import Contact from './components/contact';
import Footer from './components/footer';
import Bullet from './components/bullets';


function App() {
  return (
    <>
      <Header />
      <HomeComponent />
      
      <div id="content" className="site-content">
        <main id="main" className="site-main" role="main">
          <AboutUs/>
          <Services/>
          <Technologies/>
          <Career />
          <Contact/>
          <Footer/>
        </main>
      </div>
      <Bullet />
    </>
  );
}

export default App;
