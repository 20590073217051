import React from "react";
import { Link } from "react-scroll";
import Logo from "../assests/cropped-rocket-logo.png";

const Header = () => {

    return (
        
                <header
                    id="masthead"
                    className="site-header header-contained is-sticky no-t h-on-top header-fixed"
                    role="banner"
                    style={{top: 0}}
                >
                    <div className="container">
                        <div className="site-branding">
                            <div className="site-brand-inner has-logo-img no-desc">
                            <div className="site-logo-div">
                                <a
                                href="/"
                                className="custom-logo-link no-t-logo"
                                rel="home"
                                
                                ><img
                                    width="2065"
                                    height="805"
                                    src={Logo}
                                    className="custom-logo"
                                    alt="rockettech-solutions"
                                    loading="lazy"
                                /></a>
                            </div>
                            </div>
                        </div>
                        <div className="header-right-wrapper menu-expand-lg">
                            <a id="nav-toggle" role="button" data-bs-toggle="collapse"  href="#site-navigation" aria-controls="site-navigation" aria-expanded="false" aria-label="Toggle navigation"
                            >Menu<span></span></a>
                            <nav
                            id="site-navigation"
                            className="collapse menu-collapse main-navigation "
                            role="navigation"
                            >
                            <ul className="onepress-menu onepress-menu-mobile">
                                <li
                                id="menu-item-27"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-27 onepress-current-item"
                                >
                                <Link
                                     activeClass="active" to="about" spy={true} offset={-75} data-bs-toggle="collapse" data-bs-target=".collapse.show"
                                    
                                    >About</Link>
                                </li>
                                <li
                                id="menu-item-28"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-28 onepress-current-item"
                                >
                                <Link activeClass="active" to="services" spy={true} offset={-75} data-bs-toggle="collapse" data-bs-target=".collapse.show"
                                   
                                    >Services</Link>
                                </li>
                                <li
                                id="menu-item-1540"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1540 onepress-current-item"
                                >
                                <Link activeClass="active" to="gallery" spy={true}   offset={-75} data-bs-toggle="collapse" data-bs-target=".collapse.show"
                                    >Technologies
                                    </Link>
                                </li>
                                <li
                                id="menu-item-1603"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1603 onepress-current-item"
                                >
                                <Link activeClass="active" to="career" spy={true} offset={-75} data-bs-toggle="collapse" data-bs-target=".collapse.show"
                                    >Career
                                </Link>
                                </li>
                                <li
                                id="menu-item-32"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-32 onepress-current-item"
                                >
                                <Link activeClass="active" to="contact" spy={true} offset={-75} data-bs-toggle="collapse" data-bs-target=".collapse.show"
                                    >Contact</Link>
                                </li>
                            </ul>
                            </nav>
                            
                        </div>
                    </div>
                </header>
           
    )
}

export default Header;