import React from "react";
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  function scrollToTop() {
    scroll.scrollToTop();
  }
    return (
      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="footer-connect">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 offset-md-0">
                <div className="footer-social">
                  <div className="footer-social-icons">
                    <a href="https://www.facebook.com/rocketTECH-Solutions-Pvt-Ltd-104524025054772" target="_blank" title="">
                      <i className="fa fa fa-facebook"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/rockettech-solutions/?viewAsMember=true" target="_blank" title="">
                      <i className="fa fa fa-linkedin"></i>
                    </a>
                    <a href="https://rockettech-solutions.com/" title="">
                      <i className="fa fa fa-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/rockettech_solutions/" target="_blank" title="">
                      <i className="fa fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-info">
          <div className="container">
            <div className="btt">
              <a href="/#" className="back-to-top"  onClick={scrollToTop} title="Back To Top" style={{cursor:'pointer'}}>
                <i
                  className="fa fa-angle-double-up wow flash"
                  data-wow-duration="2s"
                ></i>
              </a>
            </div>
            copyright @ rocketTECH SOLUTIONS
          </div>
        </div>
      </footer>
    );
}
export default Footer;