import React from "react";
import {Link } from "react-scroll";

const Bullet = () => {
   
    return (
       
      <div className="c-bully c-bully-hide-on-mobile c-bully--inversed">
        
        <Link to='hero' activeClass="c-bully__bullet--active c-bully__bullet--squash" spy={true} className="c-bully__bullet c-bully__bullet--pop">
          <div className="c-bully__title">Home</div>
        </Link>
        <Link  activeClass="c-bully__bullet--active c-bully__bullet--squash" to="about" spy={true} offset={-75} className="c-bully__bullet c-bully__bullet--pop">
          <div className="c-bully__title">About</div>
        </Link>
        <Link  activeClass="c-bully__bullet--active c-bully__bullet--squash" to="services" spy={true} offset={-75} className="c-bully__bullet c-bully__bullet--pop">
          <div className="c-bully__title">Services</div>
        </Link>
        <Link  activeClass="c-bully__bullet--active c-bully__bullet--squash" to="gallery" spy={true} offset={-75} className="c-bully__bullet c-bully__bullet--pop">
          <div className="c-bully__title">Technologies</div>
        </Link>
        <Link activeClass="c-bully__bullet--active c-bully__bullet--squash" to="career" spy={true} offset={-75} className="c-bully__bullet c-bully__bullet--pop">
          <div className="c-bully__title">Career</div>
        </Link>
        <Link activeClass="c-bully__bullet--active c-bully__bullet--squash" to="contact" spy={true} offset={-75} className="c-bully__bullet c-bully__bullet--pop">
          <div className="c-bully__title">contact</div>
        </Link>
      </div>
    );
}
export default Bullet;